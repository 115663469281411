// change ul list style
ul{
  list-style: none;
}

// remove underline from link
a{
  text-decoration: none;
}

// container to center content on web-page
.container{
  max-width: $container-width;
  margin: auto;
}

// light button style
.light-btn{
  color: $black;
  background-color: $secondary-color;
  width: fit-content;
  font-size: 1.5rem;
  padding: 1.2rem 1.5rem;
  border-radius: 2rem;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  &:hover{
    background-color: darken($secondary-color, 4);
  }
}

// section-header style
.section-header{
  font-size: 3rem;
}
