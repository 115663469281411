// most outside nav-bar
#nav{
  color: $black;
  background-color: $primary-color;
}

// content inside nav container
#nav-content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 10vh;

  // navigation bar logo
  #nav-logo{
    -webkit-touch-callout: none;
    user-select: none;
    display: flex;
    padding-left: 1rem;

    // image inside outside logo container
    #nav-logo-img{
      height: 6vh;
      margin: auto;
    }
  }

  // nav-bar tabs (ul)
  #nav-tabs{
    display: flex;
    flex-direction: row;
    align-items: center;

    // nav-bar tab link (a tag inside li)
    .nav-tab {
      margin: 1rem 1.5rem;
      color: $black;
      font-size: 1.5rem;
      border-bottom: 3px transparent solid;
      padding-bottom: 0.25rem;
      transition: border-color 0.5s;
    }
    .nav-tab:hover{
      border-color: $secondary-color;
    }
  }
}


// hidden hamburger menu on desktop
.hamburger{
  display: none;
}

// media queries for mobile
@media only screen and (max-width: 1023px){

  // displaying hamburger
  .hamburger {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 0.25rem;
    height: 12vh;
  }

  // hide nav-tabs
  #nav-content {
    display: flex;
    height: 12vh;
    width: 100%;
    text-align: center;
    #nav-logo {
      #nav-logo-img {
        height: 8vh;
      }
    }

    // hide tabs
    #nav-tabs{
      display: none;
      text-align: center;
    }

    // set active state for nav menu
    #nav-tabs.nav-active {
      background-color: $primary-color;
      position: absolute;
      z-index: 1;
      display: flex;
      flex-direction: column;
      width: 100vw;
      margin-top: 12vh;
      border-bottom: $secondary-color 2px solid;
      text-align: left;
      li {
        a {
          display: inline-block;
          width: 100%;

          &:hover {
            border-bottom: $primary-color 1px solid;
          }
        }
      }
    }

  }
}


// phone landscape
@media only screen and (min-height: 320px) and (max-height: 450px) and (min-width: 400px){
  #nav-content{
    #nav-logo{
      #nav-logo-img {
        height: 12vh;
      }
    }
    #nav-logo {
      #nav-logo-img{
        padding-top: 0.25rem;
        z-index: 2;
        height: 15vh;
      }
    }
  }
  .hamburger{
    z-index: 2;
  }
}


