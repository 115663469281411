// our-story section
#our-story{
  // add background color + padding
  background-color: $background-secondary;
  padding: 6rem 0;
  text-align: center;
  #our-story-content{
    padding: 0 1rem;
    visibility: hidden;
    opacity: 0;
    transition: all 1s ease-in-out;
    transform: translate(0, -50px);
    &.show{
      transform: none;
      visibility: visible;
      opacity: 1;
    }
    .section-header {
      margin-bottom: 2rem;
    }

    .our-story-text {
      font-size: 1.6rem
    }

    .our-story-top-text{
      margin-bottom: 1rem;
    }
  }
}


// media queries

// laptops
@media only screen and (max-width: 1024px){
  // change font size and margin
  #our-story{
    padding: 4rem 0;
    #our-story-content {

      .section-header {
        margin-bottom: 1rem;
      }

      .our-story-text{
        font-size: 1.3rem;
      }
      .our-story-top-text{
        margin-bottom: 1rem;
      }
    }
  }
}

// tablets + phones
@media only screen and (max-width: 700px){
  // change font size and margin
  #our-story{
    padding: 1rem 0;
    #our-story-content {
      margin: 1.5rem 0;
      .section-header {
        font-size: 1.6rem;
        margin-bottom: 1rem;
      }

      .our-story-text{
        font-size: 1rem;
      }
    }
  }
}
