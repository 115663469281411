#not-found-section {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  background-color: $primary-color;
  #not-found-section-content {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .oops-main-title{
      font-size: 10rem;
      margin-bottom: 1rem;
    }

    .sub-text-title{
      font-size: 2.3rem;
      margin-bottom: 1rem;
    }

    .main-sub-text{
      font-size: 1.3rem;
      margin-bottom: 1.5rem;
    }
  }
}

// tablets + phones
@media only screen and (max-width: 700px){
  // change font size and margin
  #not-found-section {
    #not-found-section-content {
      background-color: $primary-color;
      padding: 0 1rem;
      .oops-main-title{
        font-size: 6rem;
        margin-bottom: 1rem;
      }

      .sub-text-title{
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }

      br{
        display: none;
      }

      .main-sub-text{
        font-size: 1rem;
        margin-bottom: 1.5rem;
      }

      .light-btn{
        padding: 1rem 1.2rem;
        margin-bottom: 1rem;
      }
    }
  }
}


// landscape phones
@media only screen and (max-height: 500px){
  // change font size and margin
  #not-found-section {
    #not-found-section-content {
      background-color: $primary-color;
      padding: 0 1rem;
      .oops-main-title{
        font-size: 6rem;
        margin-bottom: 1rem;
      }

      .sub-text-title{
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }

      br{
        display: none;
      }

      .main-sub-text{
        font-size: 1rem;
        margin-bottom: 1.5rem;
      }

      .light-btn{
        padding: 1rem 1.2rem;
        margin-bottom: 1rem;
      }
    }
  }
}



