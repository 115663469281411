// mission section - give height and center items
#mission{
  background-color: $white;

  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 6rem 0;
  #mission-content {
    text-align: center;
    padding: 0 1rem;

    visibility: hidden;
    opacity: 0;
    transition: all 1s ease-in-out;
    transform: translate(0, -50px);

    // slide in
    &.show{
      transform: none;
      visibility: visible;
      opacity: 1;
    }

    // header
    .mission-header {
      margin-bottom: 2rem;
    }

    // mission-statement
    .description-text {
      color: $black;
      font-size: 1.6rem;

    }
  }
}


// media queries

// laptops
@media only screen and (max-width: 1024px){
  // change font size and margin
  #mission {
    padding: 4rem 0;
    #mission-content {
      .section-header{
        margin-bottom: 1rem;
      }
      .description-text {
        font-size: 1.3rem;
      }
    }
  }
}

// tablets + phones
@media only screen and (max-width: 700px){
  // change font size and margin
  #mission {
    padding: 1rem 0;
    #mission-content {
      margin: 1.5rem 0;
      .section-header{
        margin-bottom: 1rem;
        font-size: 1.6rem;
      }
      .description-text {
        font-size: 1rem;
      }
    }
  }
}

