// apps section
#apps{
  // set-up background color and center items
  background-color: $primary-color;
  display: flex;
  // standard padding
  padding: 6rem 0;
  text-align: center;

  // centering items inside apps-content, which is inside #apps
  #apps-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    visibility: hidden;
    opacity: 0;
    transition: all 1s ease-in-out;
    transform: translate(0, -50px);
    &.show{
      transform: none;
      visibility: visible;
      opacity: 1;
    }
    // header
    .apps-header{
      margin-bottom: 2rem;
    }

    // changing font of text under header
    .apps-sub-text {
      font-size: 1.6rem;
    }

  }
}

// media queries

// laptops
@media only screen and (max-width: 1024px){

  // change padding, margin and fonts
  #apps{
    padding: 4rem 0;
    #apps-content {

      .apps-header{
        margin-bottom: 1.5rem;
      }
      .apps-sub-text {
        font-size: 1.3rem;
      }
    }
  }
}

// tablets + phones
@media only screen and (max-width: 700px){
  // change padding, margin and fonts
  #apps{
    padding: 1rem 0;
    #apps-content {
      margin: 1.5rem 0;
      .apps-header{
        font-size: 1.6rem;
      }
      .apps-sub-text {
        font-size: 1rem;
      }

    }
  }
}

