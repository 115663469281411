#footer{
  background-color: #808080;
  color: $white;
  padding: 1rem 0;
  #footer-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 1rem;
    #social-media-icons {
      font-size: 2.5rem;
      .social-media-icon {
        color: $primary-color;
        margin: 0 0.5rem;
        &:hover{
          color: darken($primary-color, 10)
        }
      }
    }
  }
}

// tablets + phones
@media only screen and (max-width: 700px){
  // change font size and margin
  #footer{
    #footer-content{
      text-align: center;
      flex-direction: column;
      align-items: center;
      margin: 0 1rem;
      .footer-text{
        margin-bottom: 0.5rem;
      }
      #social-media-icons {
        .social-media-icon {
          &:hover{
            color: $primary-color
          }
        }
      }
    }
  }

}

