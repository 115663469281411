// welcome section
#welcome{
  height: 90vh;
  margin: auto;
  background-color: $primary-color;
}

// content inside #welcome
#welcome-content{
  // change height and center items
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0.5rem;
}

// 1st section of welcome
#welcome-content-info{

  // change height and center items
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0.5rem;
  // change font size and color
  .header{
    font-size: 3rem;
    margin-bottom: 0.8rem;
  }
  .sub-text{
    font-size: 1.5rem;
    color: $sub-text-color;
  }
  // add margin to button
  .light-btn{
    margin-top: 1.5rem;
  }
}

// welcome img (phone mockup)
#welcome-content-img{
  width: 30%;
  display: flex;
  -webkit-touch-callout: none;
  user-select: none;
  visibility: hidden;
  opacity: 0;
  transition: all 1s ease-in-out;
  transform: translate(0, -100px);
  // slide in
  &.show{
    transform: none;
    visibility: visible;
    opacity: 1;
  }

  .mockup-img{
    width: 100%;
    height: 100%;
  }
}


// media queries

// laptops
@media only screen and (max-width: 1400px){
  #welcome-content{

    #welcome-content-img{
      .mockup-img {
        margin-top: 2rem;
      }
    }
  }
}

// laptops
@media only screen and (max-width: 1023px){
  #welcome{
    height: 88vh;
  }
  #welcome-content{
    height: 88vh;
    flex-direction: column;

    #welcome-content-info{
      width: 100%;
      text-align: center;

      .header{
        margin-top: 1rem;
      }

      br{
        display: none;
      }

      .light-btn{
        display: none;
      }
    }

    #welcome-content-img{
      width: 40%;
      margin: auto;
      .mockup-img {
        margin-top: 0;
      }
    }
  }
}

// tablets
@media only screen and (max-width: 700px){

  #welcome-content{
    #welcome-content-info{
      .header{
        font-size: 1.8rem;
        margin-top: 1.5rem;
      }
      .sub-text{
        font-size: 1.2rem;
        br{
          display: none;
        }
      }
    }

    #welcome-content-img{
      width: 55%;
      visibility: hidden;
      opacity: 0;
      transition: all 1s ease-in-out;
      transform: translate(0, -50px);
      // slide in
      &.show{
        transform: none;
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

// mobile phones
@media only screen and (max-width: 420px){

  #welcome-content{
    #welcome-content-info{
      .header{
        font-size: 1.6rem;
        margin-top: 1.5rem;
      }
      .sub-text{
        font-size: 1.2rem;
        br{
          display: none;
        }
      }
    }

    #welcome-content-img{
      width: 50%;
    }
  }
}


@media only screen and (max-width: 400px){

  #welcome-content{
    #welcome-content-info{
      .header{
        font-size: 1.6rem;
        margin-top: 1.5rem;
      }
      .sub-text{
        font-size: 1rem;
        br{
          display: none;
        }
      }
    }

    #welcome-content-img{
      width: 50%;
    }
  }
}


// iphone x
@media only screen and (min-height: 800px) and (max-height: 815px) and (max-width: 400px){

  #welcome-content{
    #welcome-content-info{
      .header{
        font-size: 1.7rem;
        margin-top: 1.5rem;
      }
      .sub-text{
        font-size: 1.2rem;
        br{
          display: none;
        }
      }
    }

    #welcome-content-img{
      width: 55%;
    }
  }
}

// phone landscape
@media only screen and (min-height: 320px) and (max-height: 450px) and (min-width: 400px){

  #welcome-content{
    flex-direction: row;
    #welcome-content-info{
      text-align: left;
      .header{
        font-size: 3rem;
        margin-top: 1.5rem;
      }
      .sub-text{
        font-size: 1.3rem;
        br{
          display: none;
        }
      }
    }

    #welcome-content-img{
      width: 26%;
    }
  }
}

// iphone x  landscape
@media only screen and (min-height: 375px) and (max-height: 400px) and (min-width: 400px){

  #welcome-content{
    flex-direction: row;

    #welcome-content-img{
      width: 22%;
      margin-right: 1rem;
    }
  }
}





