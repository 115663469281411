// colors
$primary-color: #EAF5FF;
$secondary-color: #FBE4D6;
$background-secondary: #FFF6E8;
$sub-text-color: #666666;
$input-border-color: #D3D3D3;
$black: #000000;
$white: #FFFFFF;

// fonts
$primary-font: "Poppins";

// other variables
$container-width: 1300px;


