// import fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;0,700;1,900&display=swap');

// import variables first
@import "variables";

// importing hamburger menu
@import "~hamburgers/_sass/hamburgers/hamburgers.scss";


// import main utilities
@import "utilities";

// import other partials
@import "nav", "welcome", "mission", "apps", "ourstory", "footer", "404";

// reset pre-set values
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// enable smooth scrolling
html{
  scroll-behavior: smooth;
}

// setting font + font-weight
body{
  font-family: $primary-font;
  font-weight: 300;
}




